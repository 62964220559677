import React from "react";
import Layout from "../components/Layout";
import Seo from "../components/Seo";
import Container from "../components/styles/Container";
import LinesFromRight from "../components/LinesFromRight";
import styled from "styled-components";
import { mediaMedium } from "../components/styles/variables";
import { Headline64 } from "../components/styles/Headings";
import { Body16 } from "../components/styles/BodyTypes";
import CTALink from "../components/styles/CTALink";
import { Link } from "gatsby";
import { Eyebrows12 } from "../components/styles/Eyebrows";

const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  padding: 39px 0 93px;

  @media (${mediaMedium}) {
    padding: 122px 0 188px;
  }
`;

const Decoration = styled.div`
  position: absolute;
  bottom: 0;
  width: 50%;
  left: 45%;
  top: 32%;
  display: none;

  @media (${mediaMedium}) {
    display: block;
  }
`;

const Sub = styled(Eyebrows12)`
  margin-bottom: 20px;
`;

const Title = styled(Headline64)`
  max-width: 500px;
`;

const NotFoundPage = () => (
  <Layout>
    <Seo
      title="404 Error"
      description="This page has either been deleted or does not exist. We’re sorry about that! Please navigate to our home page for more info."
    />
    <Wrapper>
      <Container>
        <Sub>404</Sub>
        <Title as="h1">We couldn't find this page.</Title>
        <Body16>
          Try going back to the{" "}
          <CTALink attrs={{ as: Link, to: "/" }}>Homepage</CTALink>.
        </Body16>
      </Container>
      <Decoration>
        <LinesFromRight />
      </Decoration>
    </Wrapper>
  </Layout>
);

export default NotFoundPage;
